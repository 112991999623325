import Moment from 'moment-timezone'

export const GetGroupOrderByClientDate =
  ({ RestService }) =>
  async ({
    clientId,
    date, // YYYY-MM-DD
  }) => {
    try {
      return await RestService.get('/group-orders/by-client-date', {
        id: clientId,
        date,
      })
    } catch (error) {
      return false
    }
  }

export const GetGroupOrderBySettingsDate =
  ({ RestService }) =>
  async ({
    settingsId,
    date, // YYYY-MM-DD
  }) => {
    try {
      return await RestService.get('/group-orders/by-settings-date', {
        id: settingsId,
        date,
      })
    } catch (error) {
      return false
    }
  }

export const SearchGroupOrdersByDateRange =
  ({ RestService, HandleError }) =>
  async ({ start, end, clientId }) => {
    const groupOrders = []
    const limit = 50
    let offset = 0

    const params = {}
    if (start != null) {
      params.start = Moment(start).format()
    }
    if (end != null) {
      params.end = Moment(end).format()
    }
    if (clientId != null) {
      params.clientId = clientId
    }

    try {
      while (true) {
        const response = await RestService.get('/group-orders', {
          ...params,
          offset,
          limit,
        })

        if (response.length === 0) {
          break
        }

        groupOrders.push(...response)
        offset += limit
      }

      return groupOrders
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const SearchGroupOrdersCount =
  ({ RestService, HandleError }) =>
  async ({ start, end, clientId, hqIds }) => {
    const params = {}
    if (start != null) {
      params.start = Moment(start).format()
    }
    if (end != null) {
      params.end = Moment(end).format()
    }
    if (clientId != null) {
      params.clientId = clientId
    }
    if (hqIds.length > 0) {
      params.hqIds = hqIds
    }

    try {
      const response = await RestService.get('/group-orders/count', params)

      return response.count
    } catch (error) {
      HandleError({ error })

      return null
    }
  }

export const SearchGroupOrderSubOrders =
  ({ RestService, HandleError }) =>
  async ({ start, end, clientId, hqIds }) => {
    const subOrders = []
    const limit = 100
    let offset = 0

    const params = {}
    if (start != null) {
      params.start = Moment(start).format()
    }
    if (end != null) {
      params.end = Moment(end).format()
    }
    if (clientId != null) {
      params.clientId = clientId
    }
    if (hqIds.length > 0) {
      params.hqIds = hqIds
    }

    try {
      while (true) {
        const response = await RestService.get('/group-orders/sub-orders', {
          ...params,
          offset,
          limit,
        })

        if (response.length === 0) {
          break
        }

        subOrders.push(...response)
        offset += limit
      }

      return subOrders
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const SearchGroupOrderSubOrdersCount =
  ({ RestService, HandleError }) =>
  async ({ start, end, clientId, hqIds }) => {
    const params = {}
    if (start != null) {
      params.start = Moment(start).format()
    }
    if (end != null) {
      params.end = Moment(end).format()
    }
    if (clientId != null) {
      params.clientId = clientId
    }
    if (hqIds.length > 0) {
      params.hqIds = hqIds
    }

    try {
      const response = await RestService.get(
        '/group-orders/sub-orders-count',
        params,
      )

      return response.count
    } catch (error) {
      HandleError({ error })

      return null
    }
  }

export const RequestSendGroupOrdersReport =
  ({ RestService, UIService, HandleError }) =>
  async ({ start, end, clientId, email, hqIds }) => {
    const params = { email }
    if (start != null) {
      params.start = Moment(start).format()
    }
    if (end != null) {
      params.end = Moment(end).format()
    }
    if (clientId != null) {
      params.clientId = clientId
    }
    if (hqIds.length > 0) {
      params.hqIds = hqIds
    }

    try {
      await RestService.get('/group-orders/report', params)
      const message =
        'Your request has been successfully received. You will receive the report via email shortly. Please allow a couple minutes for the report to generate.'
      UIService.FlashMessage.displaySuccessMessage(message)

      return true
    } catch (error) {
      HandleError({ error })

      return null
    }
  }

export const RequestSendGroupOrderItemsReport =
  ({ RestService, UIService, HandleError }) =>
  async ({ start, end, clientId, email, hqIds }) => {
    const params = { email }
    if (start != null) {
      params.start = Moment(start).format()
    }
    if (end != null) {
      params.end = Moment(end).format()
    }
    if (clientId != null) {
      params.clientId = clientId
    }
    if (hqIds.length > 0) {
      params.hqIds = hqIds
    }

    try {
      await RestService.get('/group-orders/items-report', params)
      const message =
        'Your request has been successfully received. You will receive the report via email shortly. Please allow a couple minutes for the report to generate.'
      UIService.FlashMessage.displaySuccessMessage(message)

      return true
    } catch (error) {
      HandleError({ error })

      return null
    }
  }

export const GetGroupOrderById =
  ({ RestService, HandleError }) =>
  async ({ id }) => {
    try {
      return await RestService.get(`/group-orders/${id}`)
    } catch (error) {
      HandleError({ error })

      return
    }
  }

export const MarkGroupOrderPaid =
  ({ RestService, UIService, HandleError }) =>
  async ({ id, number, paymentType, alias }) => {
    const markPaid = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to mark Group Order "${number}" as paid?`,
    })
    if (markPaid) {
      try {
        await RestService.put('/group-orders/mark_paid', {
          id,
          paymentType,
          alias,
        })
      } catch (error) {
        HandleError({ error })

        return false
      }

      return true
    }

    return false
  }

export const MarkGroupOrdersPaid =
  ({ RestService, UIService, HandleError }) =>
  async ({ ids, paymentType, alias }) => {
    const markPaid = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to mark these ${ids.length} group orders as paid?`,
    })
    if (markPaid) {
      try {
        await RestService.put('/group-orders/bulk_mark_paid', {
          ids,
          paymentType,
          alias,
        })
      } catch (error) {
        HandleError({ error })

        return false
      }

      return true
    }

    return false
  }

export const RefundOrderInGroupOrder =
  ({ RestService, UIService, HandleError }) =>
  async (order, id) => {
    const refundOrder = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to refund Order "${order.orderNumber}"?`,
    })

    if (refundOrder) {
      try {
        order.status = 'Cancelled'
        const groupOrder = await RestService.put(`/group-orders/${id}/refund`, {
          order,
        })
        UIService.FlashMessage.displaySuccessMessage(
          'Order has been successfully refunded.',
        )

        return groupOrder
      } catch (error) {
        HandleError({ error })

        return false
      }
    }
  }

export const SearchGroupOrders =
  ({ RestService, HandleError }) =>
  async ({
    search,
    searchDate,
    start,
    end,
    status,
    statuses,
    groupOrderIds,
    clientId,
    limit = 100,
    offset = 0,
    sortBy,
  }) => {
    try {
      const params = {
        offset,
        limit,
      }
      if (search != null) {
        params.search = search
      }
      if (searchDate != null) {
        params.date = searchDate.format('YYYY-MM-DD')
      }
      if (start) {
        params.start = start.format()
      }
      if (end) {
        params.end = end.format()
      }
      if (status != null) {
        params.status = status
      }
      if (statuses != null) {
        params.statuses = statuses
      }
      if (groupOrderIds != null) {
        params.groupOrderIds = groupOrderIds
      }
      if (sortBy != null) {
        params.sortBy = sortBy
      }
      if (clientId) {
        params.clientId = clientId
      }

      return await RestService.get('/group-orders', params)
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const SearchGroupOrderReviewsByDateRange =
  ({ RestService, HandleError }) =>
  async ({
    start,
    end,
    clientId,
    limit = 200,
    offset = 0,
    chefId,
    menuItemId,
  }) => {
    const params = {
      limit,
      offset,
    }
    if (start != null) {
      params.start = Moment(start).format()
    }
    if (end != null) {
      params.end = Moment(end).format()
    }
    if (clientId != null) {
      params.clientId = clientId
    }
    if (chefId != null) {
      params.chefId = chefId
    }
    if (menuItemId != null) {
      params.menuItemId = menuItemId
    }

    try {
      const response = await RestService.get('group-orders/reviews', {
        ...params,
      })

      return response
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const SearchGroupOrderReviewsCount =
  ({ RestService, HandleError }) =>
  async ({
    start,
    end,
    groupOrderStart,
    groupOrderEnd,
    clientId,
    chefId,
    menuItemId,
    hqIds,
  }) => {
    const params = {}
    if (start != null) {
      params.start = Moment(start).format()
    }
    if (end != null) {
      params.end = Moment(end).format()
    }
    if (groupOrderStart != null) {
      params.groupOrderStart = Moment(groupOrderStart).format()
    }
    if (groupOrderEnd != null) {
      params.groupOrderEnd = Moment(groupOrderEnd).format()
    }
    if (clientId != null) {
      params.clientId = clientId
    }
    if (chefId != null) {
      params.chefId = chefId
    }
    if (menuItemId != null) {
      params.menuItemId = menuItemId
    }
    if (hqIds.length > 0) {
      params.hqIds = hqIds
    }

    try {
      const response = await RestService.get(
        '/group-orders/reviews-count',
        params,
        { timeout: 30000 },
      )

      return response.count
    } catch (error) {
      HandleError({ error })

      return null
    }
  }

export const RequestSendGroupOrderReviewsReport =
  ({ RestService, UIService, HandleError }) =>
  async ({
    start,
    end,
    groupOrderStart,
    groupOrderEnd,
    clientId,
    chefId,
    menuItemId,
    email,
    hqIds,
  }) => {
    const params = { email }
    if (start != null) {
      params.start = Moment(start).format()
    }
    if (end != null) {
      params.end = Moment(end).format()
    }
    if (groupOrderStart != null) {
      params.groupOrderStart = Moment(groupOrderStart).format()
    }
    if (groupOrderEnd != null) {
      params.groupOrderEnd = Moment(groupOrderEnd).format()
    }
    if (clientId != null) {
      params.clientId = clientId
    }
    if (chefId != null) {
      params.chefId = chefId
    }
    if (menuItemId != null) {
      params.menuItemId = menuItemId
    }
    if (hqIds.length > 0) {
      params.hqIds = hqIds
    }

    try {
      await RestService.get('/group-orders/reviews-report', params)
      const message =
        'Your request has been successfully received. You will receive the report via email shortly. Please allow a couple minutes for the report to generate.'
      UIService.FlashMessage.displaySuccessMessage(message)

      return true
    } catch (error) {
      HandleError({ error })

      return null
    }
  }

export const UpdateGroupOrderFees =
  ({ RestService, UIService, HandleError }) =>
  async ({ id, feeInfo }) => {
    const params = {
      id,
    }
    if (feeInfo.changeSvcReason != null) {
      params.changeSvcReason = feeInfo.changeSvcReason
      params.adjustedServiceFee = feeInfo.adjustedSvcFee
    }
    if (feeInfo.changeTipReason != null) {
      params.changeTipReason = feeInfo.changeTipReason
      params.adjustedTipFee = feeInfo.adjustedTipFee
    }

    try {
      const groupOrder = await RestService.put(
        '/group-orders/accounting/update-fees',
        params,
      )
      UIService.FlashMessage.displaySuccessMessage(
        'Group Order fees have been successfully updated.',
      )

      return groupOrder
    } catch (error) {
      HandleError({ error })

      return false
    }
  }

export const GetGroupOrderAuditLogs =
  ({ RestService, HandleError, pResponseGroupOrderAuditLogs }) =>
  async (groupOrderId) => {
    try {
      let logs = []

      const limit = 50
      let iterations = 0
      let isFinished = false
      while (!isFinished) {
        const response = await RestService.get(
          `/group-orders/${groupOrderId}/audit-logs`,
          { limit, offset: iterations * limit },
        )

        if (response && response.length) {
          logs = [...logs, ...pResponseGroupOrderAuditLogs(response)]
        }

        if (!response || response.length < limit) {
          isFinished = true
        }
        iterations += 1
      }

      return logs
    } catch (error) {
      HandleError({ error })

      return false
    }
  }

export const PrepareGroupOrderInvoice =
  ({ RestService, HandleError }) =>
  async ({ groupOrderIds, clientId }) => {
    try {
      const result = await RestService.post('/group-orders/prepare-invoice', {
        groupOrderIds,
        clientId,
      })

      return result
    } catch (error) {
      HandleError({ error })

      return null
    }
  }

export const CalculateCurrentDeliveryFees =
  ({ RestService, HandleError }) =>
  async ({ date, clientId }) => {
    try {
      const result = await RestService.post(
        '/group-orders/process-delivery-fees',
        { date, clientId },
      )

      return result
    } catch (error) {
      HandleError({ error })

      return null
    }
  }

export const AddRefundToSubOrder =
  ({ RestService, HandleError }) =>
  async (orderId, refund) => {
    try {
      const result = await RestService.post(
        `/group-orders/accounting/add-customer-refund/${orderId}`,
        refund,
      )

      return result
    } catch (error) {
      HandleError({ error })

      return null
    }
  }
